<template>
  <div>
    <SectionHeading :content="heading.content" />
    <SectionSecondaryHeading
      :content="secondary_heading.content"
      :extra_content="secondary_heading.extra"
      hr
    />
    <v-row class="account-settings d-flex flex-row">
      <v-col class="form-fields" cols="12" xs="12" lg="6">
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="inputs.old_password"
            label="Your old password"
            type="password"
            :rules="[(v) => !!v || 'Your old password is required']"
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="inputs.new_password"
            label="Your new password"
            type="password"
            :rules="passwordRules"
            outlined
            required
          ></v-text-field>

          <v-text-field
            v-model="inputs.confirm_password"
            label="Confirm your new password"
            :rules="confirmPasswordRules"
            type="password"
            outlined
            required
          ></v-text-field>

          <v-btn @click="changePwd" color="#286aff" class="mr-4">
            Change Password
          </v-btn>
        </v-form>
      </v-col>

      <v-col cols="12" xs="12" lg="6">
        <v-card height="100%" class="mx-auto" max-width="450" min-width="350">
          <v-card-title class="d-flex flex-column justify-center">
            Your API Key
          </v-card-title>
          <v-card-text class="text-center mt-3">
            <v-text-field
              :value="user.api_key || 'No Key'"
              disabled
              outlined
            ></v-text-field>

            <div class="d-flex flex-row justify-center align-center">
              <v-btn @click="resetKey" class="mx-2">Reset API Key</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectionHeading from "../components/SectionHeading.vue";
import SectionSecondaryHeading from "../components/SectionSecondaryHeading.vue";
export default {
  name: "Search",

  components: { SectionHeading, SectionSecondaryHeading },

  computed: {
    user() {
      return this.$jwt(localStorage.token);
    },
  },

  methods: {
    async resetKey() {
      try {
        await this.$http.post("/user/reset_api_key");

        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content:
            "Your API key was reset successfully. Please re-log to see the new api key.",
        });
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Oops!`,
          content: "Please, try again. If problem persist, contact us",
        });
      }
    },

    async changePwd() {
      if (this.inputs.new_password != this.inputs.confirm_password) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Oops!`,
          content: "Please, make sure all your required fields are valid.",
        });

        return;
      }

      const data = {
        new_password: this.inputs.new_password,
        old_password: this.inputs.old_password,
      };

      try {
        var req = await this.$http
          .post("/user/password", data)
          .then((res) => res.data);

        if (req.status == false) throw "";

        this.$dtoast.pop({
          preset: "success",
          heading: `Success!`,
          content: "You changed your password successfully.",
        });
      } catch (err) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Oops!`,
          content: req.message,
        });
      }
    },
  },

  data() {
    return {
      heading: {
        content: "Account Settings",
      },

      secondary_heading: {
        content: "Change Password",
        extra: "",
      },

      inputs: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length > 8 || "Password must be atleast 8 characters long",
        (v) =>
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v) ||
          "Password should be of atleast one letter and one number",
      ],

      confirmPasswordRules: [
        (v) => v == this.inputs.new_password || "Passwords doesn't match",
      ],
    };
  },
};
</script>

<style lang="scss">
.account-settings {
  .v-card {
    min-height: 250px !important;
    background: var(--v-support_cards_bg-base) !important;
    border-radius: 15px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .account-settings {
    padding: 20px 52px;
    align-items: center;
  }

  .form-fields {
    padding: 40px;
    max-width: 600px;
    min-width: 400px;

    .v-input {
      padding: 0px 0;
    }
  }
}
</style>

