<template>
  <v-container fluid>
    <v-row>
    <v-col cols="12" lg="3">
      <v-card>

        <v-card-title class="font-weight-light">
          API INFORMATION
        </v-card-title>

        <v-card-text>
          <v-text-field
            label="API Key:"
            :value="api_key"
            append-icon="mdi-content-copy"
            @click:append="copy"
            readonly
          />
        </v-card-text>

      </v-card>
    </v-col>
    <v-col cols="12" lg="3">
      <v-card>

        <v-card-title class="font-weight-light">
          SECURITY
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field label="Current Password" outlined />
            <v-text-field label="New Password" outlined />
            <v-text-field label="Confirm Password" outlined />
            <v-btn color="secondary" block>
              Change Password
            </v-btn>
          </v-form>
        </v-card-text>

      </v-card>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    api_key: '286fc12b-9147-4849-980a-1345e3dbdc34'
  }),
  methods: {
    copy() {
      navigator.clipboard.writeText(this.api_key);
    }
  }
}
</script>